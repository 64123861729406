<template>
  <div>
    <b-card> Frequently Asked Questions </b-card>
    <app-collapse
      v-for="(faq, index) in faqs"
      v-bind:key="index"
      class="mb-1"
      visible
      accordion
    >
      <app-collapse-item visible :title="index + 1 + '. ' + faq.question + '?'">
        {{ faq.answer }}
        <b-button
          @click="useful(faq.hashid)"
          variant="outline-primary"
          class="mx-auto d-block"
        >
          <feather-icon
            v-b-tooltip.hover
            title="Useful"
            icon="HeartIcon"
            size="16"
            class="mr-1 cursor-pointer"
          />
          Useful <span v-if="faq.likes_count">({{ faq.likes_count }})</span>
        </b-button>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import faqStoreModule from "./faqStoreModule";
import useFaqList from "./list/useFaqList";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-faqs";
    const isDeleteMultipleCandChecked = ref(false);
    const selectedFaqs = ref([]);
    const faqsDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(REFERRAL_APP_STORE_MODULE_NAME, faqStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    return {};
  },
  data() {
    return {
      faqs: [],
    };
  },
  created() {
    this.getFaqsData();
  },
  methods: {
    editRecord(data) {
      this.$router.push(`/edit/faq/${data.item.hashid}`).catch(() => {});
    },
    viewRecord(data) {
      this.$router.push(`/view/faq/${data.item.hashid}`).catch(() => {});
    },
    getFaqsData() {
      const self = this;
      let data = {};
      data.url = "faqs/";
      data.params = {
        status: "1",
      };

      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.faqs = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! FAQ Getting Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    useful(hashid) {
      const self = this;
      let data = {};
      data.url = "faqs/useful/" + hashid;
      // data.params = self.data_local;

      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.getFaqsData();
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Faq Marked as Useful",
              icon: "BellIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.log(error);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! FAQ Getting Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-faqs/removeFaq", id)
        .then((res) => {
          if (res.status == "204") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Faq Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Faq Deleted Successfully.",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Faq Deletion failed.",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
          self.refetchData();
        })
        .catch((err) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Faq Deletion failed.",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
